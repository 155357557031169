<template>
  <div>
    <div id="float-btns" class="l-btns l-container" :class="{ 'l-float': floating}">
      <slot></slot>
    </div>
    <span class="l-float-fix-area"></span>
  </div>
</template>

<script>
export default {
  name: "FloatButtons",
  data() {
    return {
      floating: true,
    };
  },
  methods: {
    handleScroll() {
      const maxHeight = document.getElementById("main").clientHeight;
      const btnHeight = document.getElementById("float-btns").clientHeight;
      const threshold = maxHeight - 600 - btnHeight;
      if (!this.floating) {
        this.floating = window.scrollY < threshold - 30;
      } else if (window.scrollY > threshold) {
        this.floating = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
}
</script>