<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="CBA加入者（排出）" />
    <AppSideMenu />
    <main id="main" class="l-main">
      <div class="l-container" v-if="!isGenerator">
        <div class="c-alart">
          <div class="icon"></div>
          <p>契約データの一括登録中に、関連事業者の情報を編集しないでください。実施前後には必ず社内への周知を行ってください。</p>
        </div>
      </div>
      <div class="l-container" v-if="Object.keys(form).length">
        <!-- 企業情報 ================================================== -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">企業情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>会社名/営業所名</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateContractInfo.contractName | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>住所</label>
              </div>
              <div class="c-inputWrap__items">
                〒{{ form.generateContractInfo.zipCode }}
                {{ form.generateContractInfo.prefecturesName }}
                {{ form.generateContractInfo.address }}
                {{ form.generateContractInfo.buildingName }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateContractInfo.tel | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>FAX番号</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateContractInfo.fax | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>所属グループ</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateContractGroupInfo ? form.generateContractGroupInfo.name : "-" }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>JWNET使用回線</label>
              </div>
              <div class="c-inputWrap__items">
                {{ jwnetLineTypes.find(it => it.value === form.generateContractInfo.jwnetLineTypeId).name }}
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->

        <!-- 契約情報 ================================================== -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">契約情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>契約プラン</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateContractInfo.contractTypeName | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>契約開始日</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateContractInfo.cbaContractStartDate | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>契約終了日</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateContractInfo.cbaContractEndDate | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>契約自動更新</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateContractInfo.isAutoUpdate ? "有" : "無" }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>割引キャンペーン対象</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateContractInfo.isDiscountCampaign ? "有" : "無" }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>請求方法</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  form.generateContractInfo.invoiceMethodTypeName | blankText
                }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>請求タイミング</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateContractInfo.invoiceTypeName | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>請求先</label>
              </div>
              <div class="c-inputWrap__items">
                〒{{ form.generateContractInfo.invoiceZipCode }}<br />
                {{ form.generateContractInfo.invoicePrefecturesName }}
                {{ form.generateContractInfo.invoiceAddress }}
                {{ form.generateContractInfo.invoiceBuildingName }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>担当者部署</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  form.generateContractInfo.contractStaffDepartment | blankText
                }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>担当者名</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateContractInfo.contractStaffName | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>担当者メールアドレス</label>
              </div>
              <div class="c-inputWrap__items word-break">
                {{ form.generateContractInfo.contractStaffMail | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>備考</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateContractInfo.remarks | blankText }}
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->

        <!-- 管理者アカウント情報 ================================================== -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">管理者アカウント情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>部署</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateContractManagementMemberInfo.department | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>氏名</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateContractManagementMemberInfo.name | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>メールアドレス</label>
              </div>
              <div class="c-inputWrap__items word-break">
                {{ form.generateContractManagementMemberInfo.mail | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateContractManagementMemberInfo.tel | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>電話番号(携帯)</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  form.generateContractManagementMemberInfo.mobilePhone
                    | blankText
                }}
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->

        <!-- 排出事業者情報 ================================================== -->
        <div
          class="l-contents l-box"
          v-for="list in form.generateCompanyInfoList"
          :key="list.id"
        >
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">排出事業者情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>事業者名</label>
              </div>
              <div class="c-inputWrap__items">
                {{ list.name | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>住所</label>
              </div>
              <div class="c-inputWrap__items">
                〒{{ list.zipCode }}<br />
                {{ list.prefecturesName }}
                {{ list.address }}
                {{ list.buildingName }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                {{ list.tel | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>FAX番号</label>
              </div>
              <div class="c-inputWrap__items">
                {{ list.fax | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>JWNETID(加入者番号)</label>
              </div>
              <div class="c-inputWrap__items">
                {{ list.jwnetContractNo | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>EDI利用確認キー</label>
              </div>
              <div class="c-inputWrap__items">
                {{ list.ediKey | blankText }}
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>事業所/事業場情報</label>
              </div>
              <div class="c-inputWrap__items">
                <div
                  class="c-accordion"
                  v-for="factoryList in list.generateFactoryInfoList"
                  :key="'factoryList-' + factoryList.id"
                >
                  <input
                    :id="'factoryList-acc-' + factoryList.id"
                    class="c-accordion__cb"
                    type="checkbox"
                  />
                  <div class="c-accordion__head">
                    <label
                      class="c-accordion__head__lbl"
                      :for="'factoryList-acc-' + factoryList.id"
                    ></label>
                    <div class="c-accordion__head__text">
                      {{ factoryList.name }}
                    </div>
                  </div>
                  <div class="c-accordion__body">
                    <div
                      class="c-infoText"
                      v-for="(item, index) in factoryList.generateStoreInfoList"
                      :key="'item-' + factoryList.id + '-' + index"
                    >
                      <div class="c-infoText__text">{{ item.name }}</div>
                      <div class="c-infoText__tip">
                        <!-- <h6 class="c-infoText__tip__lead">ABC部門</h6> -->
                        <div class="c-dl-wide">
                          <dt class="sm">住所</dt>
                          <dd>
                            〒{{ item.zipCode }}<br />
                            {{ item.prefecturesName }}
                            {{ item.address }}
                            {{ item.buildingName }}
                          </dd>
                        </div>
                        <div class="c-dl-wide">
                          <dt class="sm">電話番号</dt>
                          <dd>{{ item.tel | blankText }}</dd>
                        </div>
                        <div class="c-dl-wide">
                          <dt class="sm">FAX番号</dt>
                          <dd>{{ item.fax | blankText }}</dd>
                        </div>
                        <div class="c-dl-wide">
                          <dt class="sm">空予約自動保有件数</dt>
                          <dd>{{ item.autoEmptyReservePossessNumb | blankText }}</dd>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->
      </div>

      <FloatButtons>
        <div class="center">
          <router-link v-if="!isGenerator" to="/generator/contract/" class="c-btn secondary small back">一覧へ戻る</router-link>
          <div v-if="isCba"
               class="c-btn secondary small"
               @click="isActiveChangeModalShow = true">
            {{ form.generateContractInfo && form.generateContractInfo.isActive ? "無効化" : "有効化" }}
          </div>
          <router-link v-if="isCba"
                       :to="`/generator/contract/step1/edit/${contractId}`"
                       class="c-btn secondary small">編集</router-link>
          <div v-if="isCba"
               class="c-btn primary mid"
               :class="{ disabled: !form.generateContractInfo || !form.generateContractInfo.isHasEntrustGenerateStore || isAfterStartUseCba }"
               @click="isAccountModalShow = true">
            アカウント告知
          </div>
        </div>
        <router-link v-if="isCba && isAdmin" to="/account" class="c-btn secondary small next">アカウント一覧へ</router-link>
        <router-link v-if="isCba" to="/entrust" class="c-btn secondary small next">委託契約一覧へ</router-link>
        <router-link v-if="isCba" to="/route" class="c-btn secondary small next">ルート一覧へ</router-link>
      </FloatButtons>
    </main>

    <!-- アカウント告知モーダル -->
    <div class="c-modal md" v-show="isAccountModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__head">
          <h5 class="c-lead-s">アカウント告知</h5>
          <span
            class="c-modal__head__close"
            @click="isAccountModalShow = false"
          ></span>
        </div>
        <div class="c-modal__body no-scroll">
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>サービス開始日</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <InputDate
                  :value.sync="startDate"
                  placeholder="選択してください"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn primary small"
            :class="{ disabled: !startDate }"
            @click="isAccountModalConfirmShow = true"
          >
            確認
          </div>
        </div>
      </div>
    </div>

    <!-- アカウント告知確認モーダル -->
    <div class="c-modal md" v-show="isAccountModalConfirmShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body no-scroll">
          <ErrorMessage :errMsgs="errMsgs" />
          事業者に運用開始可能を通知<br />
          するメールを送ります。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="isAccountModalConfirmShow = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" @click="setStartDateHandler">OK</div>
        </div>
      </div>
    </div>

    <!-- 有効/無効化モーダル -->
    <div class="c-modal md" v-if="isActiveChangeModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body no-scroll">
          CBA加入契約を{{ form.generateContractInfo && form.generateContractInfo.isActive ? "無効化" : "有効化" }}します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="isActiveChangeModalShow = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" @click="setActiveChangeHandler()">
            OK
          </div>
        </div>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import callApi from "./mixins/callApi";
import InputDate from "@/components/parts/input/InputDate";
import {DOMAIN, JWNET_LINE_TYPE} from "@/const";
import { API_URL } from "@/const";
import FloatButtons from "@/components/layout/FloatButtons";

export default {
  name: "contract-detail",
  mixins: [callApi],
  components: {
    FloatButtons,
    InputDate,
  },
  data() {
    return {
      contractId: null,
      startDate: "",
      isAccountModalShow: false,
      isAccountModalConfirmShow: false,
      isActiveChangeModalShow: false,
      errMsgs: [],
      isCba: this.$store.getters["userInfo/isCba"],
      isAdmin: this.$store.getters["userInfo/isAdmin"],
      isGenerator: this.$store.getters["userInfo/isGenerator"],
      jwnetLineTypes: JWNET_LINE_TYPE,
    };
  },
  computed: {
    //CBAの利用開始後であるか判定
    isAfterStartUseCba() {
      return (this.form.generateContractInfo.contractStatusTypeId === DOMAIN.CONTRACT_STATUS_TYPE.CONTRACT
             || this.form.generateContractInfo.contractStatusTypeId === DOMAIN.CONTRACT_STATUS_TYPE.EXPIRED
             || this.form.generateContractInfo.contractStatusTypeId === DOMAIN.CONTRACT_STATUS_TYPE.END)
    },
  },

  methods: {
    setStartDateHandler() {
      this.errMsgs = [];
      const reqParam = {
        generateContractId: this.form.generateContractInfo.generateContractId,
        accountStartDate: this.startDate,
        managementMemberId: this.form.generateContractManagementMemberInfo.id,
      };
      axios.put(API_URL.GENERATOR.COMPLETE_MAIL, reqParam)
        .then(() => {
          this.isAccountModalShow = false;
          this.isAccountModalConfirmShow = false;
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.isAccountModalShow = true;
          console.log(err);
        });
    },

    // 有効/無効化更新
    setActiveChangeHandler() {
      const isActive = !this.form.generateContractInfo.isActive;
      axios
        .patch(API_URL.GENERATOR.ACTIVE_STATUS + this.contractId, {isActive})
        .then(() => {
          // リロード
          this.$router.go({
            path: this.$router.currentRoute.path,
            force: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  mounted() {
    this.contractId = this.$route.params.id;
    this.getGeneratorContractInfoApi(this.contractId);
  },
};
</script>
